import { render, staticRenderFns } from "./lotto-list.vue?vue&type=template&id=23de48e3&scoped=true&"
import script from "./lotto-list.vue?vue&type=script&lang=js&"
export * from "./lotto-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23de48e3",
  null
  
)

export default component.exports